import { get, post } from '@/utils/request'

export default {
  getList(params = {}) {
    return get(`/rest/room`, params)
  },
  add(body) {
    return post(`/rest/room`, body)
  },
  entry(room_id) {
    return get(`/rest/room/in/${room_id}`)
  },
  permission(dipm_id) {
    return get(`/rest/room/permission/${dipm_id}`)
  },
  detail(uuid) {
    return get(`/rest/room?room_id=${uuid}`)
    .then(res => {
      return res && res.list && res.list[0]
    })
  },
}
